import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class BlogRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className='columns is-multiline mt-3 blog-list'>
        {posts &&
          posts.map(({ node: post }) => (
            <article className='is-parent column is-6' key={post.id}>
              <div className={`blog-list-item tile is-child ${post.frontmatter.featuredpost ? "is-featured" : ""}`}>
                <Link to={post.fields.slug}>
                  <p className='subtitle'>{post.frontmatter.date}</p>
                  {post.frontmatter.featuredimage ? (
                    <div className='featured-thumbnail'>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          width: post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.width,
                          height: post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.height,
                        }}
                      />
                    </div>
                  ) : null}
                  <p className='post-meta title is-size-4 mt-2'>{post.frontmatter.title}</p>
                </Link>
                {post.frontmatter.tags && post.frontmatter.tags.length ? (
                  <p className='has-text-right mt-2'>
                    {post.frontmatter.tags.map((tag) => (
                      <Link className='button is-small' key={tag + `tag`} to={`/tags/${kebabCase(tag)}/`}>
                        {tag}
                      </Link>
                    ))}
                  </p>
                ) : null}
              </div>
            </article>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: { frontmatter: { templateKey: { eq: "blog-post" } } }) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  tags
                  templateKey
                  date(formatString: "YYYY/MM/DD")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
