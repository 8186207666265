import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import BlogHeader from "../../components/BlogHeader";
import BlogRoll from "../../components/BlogRoll";
import TagList from "../../components/TagList";
export default class BlogIndexPage extends React.Component {
  render(pageTitle) {
    pageTitle = "CONNECT BLOG";
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                siteUrl
              }
            }
          }
        `}
        render={(data) => (
          <Layout>
            <Helmet
              title={pageTitle + " | " + data.site.siteMetadata.title}
              meta={[
                {
                  property: "og:title",
                  content: `${pageTitle} | ${data.site.siteMetadata.title}`,
                },
                {
                  property: "og:url",
                  content: `${data.site.siteMetadata.siteUrl}/blog`,
                }
              ]}
            />
            <BlogHeader />
            <div className='container'>
              <div className='section pt-1'>
                <div className='columns'>
                  <div className='column is-9 content'>
                    <BlogRoll />
                  </div>
                  <aside className='column'>
                    <h2 className='orbitron heading is-size-4'>Tags</h2>
                    <TagList />
                  </aside>
                </div>
              </div>
            </div>
          </Layout>
        )}
      />
    );
  }
}
