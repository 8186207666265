import React from "react";
import { Link } from "gatsby";

const BlogHeader = class extends React.Component {
  render() {
    return (
      <Link to='/blog'>
        <div
          className='full-width-image-container my-0'
          style={{
            backgroundImage: `url('/img/blog.jpg')`,
            backgroundPosition: `center`,
          }}
        ></div>
      </Link>
    );
  }
};

export default BlogHeader;
